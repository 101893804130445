'use client';
import React from 'react'
import Landing from './home/page'




const Home = () => {
 


  return (
   <Landing />
   
    )
}

export default Home;



